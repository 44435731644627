// Función para formatear la fecha
export const formatearFecha = (fechaString) => {
  const fecha = new Date(fechaString);
  const mesesEnLetra = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre",
  ];

  return (
    fecha.getDate() +
    "|" +
    mesesEnLetra[fecha.getMonth()] +
    "|" +
    fecha.getFullYear()
  ).split("|");
};


//-----------EVENTOS-----------//

// Función para cargar eventos
export const loadEvents = (el) => {
  
  const $events = document.querySelector(el);
  axios
    .post('https://www.aguascalientes.gob.mx/geaws/api/gea/eve/ws/aut', null, {
      "Content-Type": "application/x-www-form-urlencoded",
    })
    .then((response) => {
      if ($events) {
        $events.innerHTML = `${response.data
          .map(
            (el) => `<div class="swiper-slide">
              <div onclick="viewMore(${el.id_evento})" class="card event">
                <div id="eventImageWrapper" class="card_image-wrapper event_image-wrapper" style="background:url(${el.imagen + '/100'})"></div>
                <img id="sourceImage" src="${el.imagen + '/300'}" alt="" class="poster">
                <div class="card_content event_content">
                  <h5 class="title text-uppercase text-primary">${el.nombre}</h5>
                  <h6 class="title text-uppercase text-primary">
                    ${formatearFecha(el.fecha)[0]} de 
                    ${formatearFecha(el.fecha)[1]}
                  </h6>
                  <p class="text-uppercase"><b>Lugar:</b> ${el.subzona}</p>
                  <p class="text-uppercase"><b>Costo:</b> ${el.costo === "" ? "Gratuito" : el.costo}</p>
                </div>
              </div>
            </div>`
          )
          .join("")}`;
      }
    })
    .catch(() => {
      $events.innerHTML = "";
    });
};

// Función para ver más detalles de un evento
export const viewMore = (id_evento) =>
  window.open(
    `https://eservicios2.aguascalientes.gob.mx/eventosPortal/evento/${id_evento}`,
    "_blank"
);

//-----------NOTICIAS-----------//


export const loadPosts = (n, el) => {
  const $posts = document.querySelector(el);

  if ($posts) {
    axios
      .post('https://www.aguascalientes.gob.mx/geaws/api/gea/bol/ws/btportaljson')
      .then(function (response) {
        $posts.innerHTML = `${response.data
          .slice(0, n)
          .map(
            (el) => ` <a onclick="viewMorePost(${
              el.id_boletinportal
            })" class="post">
        <div class="post_image">
          <img src="https://eservicios2.aguascalientes.gob.mx/ssi/utilerias/files/portal/b${
            el.id_boletinportal
          }.png" class="" />
        </div>
        <div class="post_resume">
          <h4
            class="title fw-bold text-uppercase text-primary post_resume-title"
          >
            ${el.titulo}
          </h4>
          <p class="badge bg-p-green text-a-green text-uppercase p-3">
          ${
            formatearFecha(el.fecha)[0] +
            " de " +
            formatearFecha(el.fecha)[1] +
            " del " +
            formatearFecha(el.fecha)[2]
          }
          </p>
          <div class="post_resume-excerpt p-0">
          ${el.nota.replace(/\r\n/g, "<br>")}
          </div>

          <button class="btn btn-link mt-3">Seguir Leyendo</button>
        </div>
      </a>`
          )
          .join("")}
      `;
      })
      .catch(function (error) {
        $posts.innerHTML = "";
      });
  }
};


export  const loadPostsNews = (n, el) => {
    const $posts = document.querySelector(el);
    if ($posts) {
      axios
        .post('https://www.aguascalientes.gob.mx/geaws/api/gea/bol/ws/btportaljson')
        .then(function (response) {
          //console.log("RESPUESTA", response);
          $posts.innerHTML = `${response.data
            .slice(0, n)
            .map(
              (
                el
              ) => `<a target="_self" onclick="viewMorePost(${el.id_boletinportal})" class="last_post">
                <img
                  src="https://eservicios2.aguascalientes.gob.mx/ssi/utilerias/files/portal/b${el.id_boletinportal}.png"
                  alt=""
                  class="last_post-thumb"
                />
                <div class="last_post-contain">
                  <h5 class="title fw-bold">
                   ${el.titulo}
                  </h5>
                  <button type="button" onclick="viewMorePost(${el.id_boletinportal})" class="btn btn-link">
                    Seguir leyendo
                  </button>
                </div>
              </a>`
            )
            .join("")}
          `;
        })
        .catch(function (error) {
          $posts.innerHTML = "";
        });
    }
};


export const loadPostDep = (siglas,url,n) => { // Añadido `n` como argumento
  console.log('SIGLAS ' + siglas);
  console.log('URL ' + url);

  const $postDep = document.getElementById('noticiasDependencia');
  console.log($postDep);

  const data = new URLSearchParams();
  data.append("", siglas);

  const config = {
    method: 'post',
    url: 'https://egobierno2.aguascalientes.gob.mx/geaws/api/gea/bol/ws/btportaljson/'+siglas,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };

  if ($postDep) {
    axios(config)
      .then(response => {
        console.log(response.data);
        // Aquí puedes agregar el código para manipular el DOM con la respuesta
        $postDep.innerHTML = response.data
          .slice(0, n)
          .map(el => `
            <div class="posts mt-4" id="catPostsX">
              <a onclick="window.open('${url}noticia/?id=${el.id_boletinportal}', '_self')" class="post">
                <div class="post_image">
                  <img src="https://eservicios2.aguascalientes.gob.mx/ssi/utilerias/files/portal/b${el.id_boletinportal}.png" class="">
                </div>
                <div class="post_resume">
                  <h4 class="title fw-bold text-uppercase text-primary post_resume-title">
                    ${el.titulo}
                  </h4>
                  <p class="badge bg-p-green text-a-green text-uppercase p-3">
                    ${
                      formatearFecha(el.fecha)[0] +
                      " de " +
                      formatearFecha(el.fecha)[1] +
                      " del " +
                      formatearFecha(el.fecha)[2]
                    }
                  </p>
                  <div class="post_resume-excerpt p-0">
                    ${el.nota}
                  </div>
                  <button onclick="window.open('${url}noticia/?id=${el.id_boletinportal}', '_self')" class="btn btn-link mt-3">Seguir Leyendo</button>
                </div>
              </a>
            </div>
          `)
          .join("");
      })
      .catch(error => {
        console.error('Error en la solicitud:', error);
      });
  } else {
    console.warn('Elemento con id "noticiasDependencia" no encontrado.');
  }
  console.log('fin');
};


export const viewMorePost = (id_boletin) => window.open(`noticia/?id=${id_boletin}`, "_self");